import React from "react";
import PropTypes from "prop-types";
import {
  Dropdown,
  Form,
  Grid,
  Label,
  Segment,
  Select,
} from "semantic-ui-react";
import FormCliente_CF from "./FormCliente_CF";
import useWindowSize from "../../utils/useWindowSize";
//import { useTranslation } from "react-i18next";

function FormCliente({
  handleFieldChange,
  datiCliente,
  listaNazioni,
  listaNazioniDial,
  listaLingue,
  isCheckout,
  errors,
  t,
  ...props
}) {
  //const { i18n, t } = useTranslation();
  const windowSize = useWindowSize();
  return (
    <Form>
      <Form.Input
        fluid
        label={t("nome")}
        placeholder={t("nome")}
        name="nome"
        onChange={handleFieldChange}
        value={datiCliente.nome}
        error={
          errors.nome
            ? {
                content: t("nome_error"),
                pointing: "below",
              }
            : null
        }
      ></Form.Input>
      <Form.Input
        fluid
        label={t("cognome")}
        placeholder={t("cognome")}
        name="cognome"
        onChange={handleFieldChange}
        value={datiCliente.cognome}
        error={
          errors.cognome
            ? {
                content: t("cognome_error"),
                pointing: "below",
              }
            : null
        }
      ></Form.Input>
      <Form.Dropdown
        fluid
        label={t("nazione")}
        selection
        options={listaNazioni}
        placeholder={t("nazione")}
        name="nazione"
        onChange={handleFieldChange}
        value={datiCliente.nazione}
        search
        error={
          errors.nazione
            ? {
                content: t("nazione_error"),
                pointing: "below",
              }
            : null
        }
      />

      {props.datiScuola.usaCodiceFiscale === true && (
        <FormCliente_CF
          t={t}
          errors={errors}
          nazione={datiCliente.nazione}
          onFieldChange={handleFieldChange}
          cf={datiCliente.cf}
          dataNascita={datiCliente.dataNascita}
          luogoNascita={datiCliente.luogoNascita}
        />
      )}

      <Form.Group>
        <Form.Input
          fluid
          label={t("citta")}
          width={10}
          placeholder={t("citta")}
          name="citta"
          onChange={handleFieldChange}
          value={datiCliente.citta}
          error={
            errors.citta
              ? {
                  content: t("citta_error"),
                  pointing: "below",
                }
              : null
          }
        ></Form.Input>
        <Form.Input
          fluid
          label={t("cap")}
          width={6}
          placeholder={t("cap")}
          name="cap"
          onChange={handleFieldChange}
          value={datiCliente.cap}
          // error={
          //   errors.cap
          //     ? {
          //         content: t("cap_error"),
          //         pointing: "below",
          //       }
          //     : null
          // }
        ></Form.Input>
      </Form.Group>
      <Form.Input
        fluid
        label={t("indirizzo")}
        placeholder={t("indirizzo")}
        name="indirizzo"
        onChange={handleFieldChange}
        value={datiCliente.indirizzo}
        error={
          errors.indirizzo
            ? {
                content: t("indirizzo_error"),
                pointing: "below",
              }
            : null
        }
      ></Form.Input>
      <Form.Input
        fluid
        label={t("email")}
        placeholder={t("email")}
        name="email"
        onChange={handleFieldChange}
        value={datiCliente.email}
        error={
          errors.email
            ? {
                content: t("email_error"),
                pointing: "below",
              }
            : null
        }
      ></Form.Input>
      <Grid>
        {windowSize.width >= 600 && (
          <Grid.Row>
            <Grid.Column computer={isCheckout ? 4 : 3}>
              <label>Country Code</label>
              <Dropdown
                placeholder="Country Code"
                fluid
                name="prefisso"
                onChange={handleFieldChange}
                value={datiCliente.prefisso}
                search
                selection
                options={listaNazioniDial}
                error={
                  errors.prefisso
                    ? {
                        content: t("prefisso_error"),
                        pointing: "below",
                      }
                    : null
                }
              />
            </Grid.Column>
            <Grid.Column computer={isCheckout ? 12 : 13}>
              <Form.Input
                fluid
                label={t("cellulare")}
                placeholder={t("cellulare")}
                name="telefono"
                onChange={handleFieldChange}
                value={datiCliente.telefono}
                error={
                  errors.telefono
                    ? {
                        content: t("cellulare_error"),
                        pointing: "below",
                      }
                    : null
                }
              ></Form.Input>
            </Grid.Column>
          </Grid.Row>
        )}
        {windowSize.width < 600 && (
          <>
            <Grid.Row>
              <Grid.Column>
                <label>Country Code</label>
                <Dropdown
                  placeholder="Country Code"
                  fluid
                  name="prefisso"
                  onChange={handleFieldChange}
                  value={datiCliente.prefisso}
                  search
                  selection
                  options={listaNazioniDial}
                  error={
                    errors.prefisso
                      ? {
                          content: t("prefisso_error"),
                          pointing: "below",
                        }
                      : null
                  }
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column style={{ marginTop: "-14px" }}>
                <Form.Input
                  fluid
                  label={t("cellulare")}
                  placeholder={t("cellulare")}
                  name="telefono"
                  onChange={handleFieldChange}
                  value={datiCliente.telefono}
                  error={
                    errors.telefono
                      ? {
                          content: t("cellulare_error"),
                          pointing: "below",
                        }
                      : null
                  }
                ></Form.Input>
              </Grid.Column>
            </Grid.Row>
          </>
        )}
      </Grid>
      <div style={{ paddingTop: "14px" }}>
        <Form.Field
          control={Select}
          fluid
          label={t("preferenza_lingua")}
          selection
          options={listaLingue}
          placeholder={t("lingua")}
          name="lingua"
          onChange={handleFieldChange}
          value={datiCliente.lingua}
          style={{ marginBottom: "14px" }}
          search
        />
      </div>
      {/* <Form.Dropdown
        
      /> */}
    </Form>
  );
}

FormCliente.propTypes = {
  handleFieldChange: PropTypes.func.isRequired,
  datiCliente: PropTypes.object.isRequired,
  listaNazioni: PropTypes.array.isRequired,
  errors: PropTypes.object.isRequired,
  datiScuola: PropTypes.object.isRequired,
};

export default FormCliente;
