import React, { useState } from "react";
import { Form, Input } from "semantic-ui-react";

const FormCliente_CF = ({ nazione, t, ...props }) => {
  const [day, setDay] = useState("");
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");

  const [data, setData] = useState(null);

  function isValidDate(year, month, day) {
    var d = new Date(year, month, day);
    if (
      d.getFullYear() === year &&
      d.getMonth() === month &&
      d.getDate() === day
    ) {
      return true;
    }
    return false;
  }

  const onDataChange = (val) => {
    let newData = null;

    if (val.target.name === "giorno") {
      const re = /^[0-9\b]+$/;
      if (val.target.value !== "" && !re.test(val.target.value)) {
        val.target.value = day;
        return;
      }
    }

    if (val.target.name === "mese") {
      const re = /^[0-9\b]+$/;
      if (val.target.value !== "" && !re.test(val.target.value)) {
        val.target.value = month;
        return;
      }
    }

    if (val.target.name === "anno") {
      const re = /^[0-9\b]+$/;
      if (val.target.value !== "" && !re.test(val.target.value)) {
        val.target.value = year;
        return;
      }
    }

    switch (val.target.name) {
      case "giorno":
        if (val.target.value > 31) {
          return;
        }
        setDay(val.target.value);
        newData = new Date(val.target.value, month - 1, day, 8, 0, 0, 0);
        setData(newData);

        if (year === "" || month === "")
          props.onFieldChange(null, { name: "dataNascita", value: null });
        else props.onFieldChange(null, { name: "dataNascita", value: newData });
        break;
      case "mese":
        if (val.target.value > 12) {
          return;
        }

        setMonth(val.target.value);
        //controllo il giorno
        //debugger;
        if (day > 28) {
          if (year > 1800) {
            if (!isValidDate(year, val.target.value - 1, day)) {
              setDay("");
              return;
            }
          }
        }

        newData = new Date(val.target.value, month - 1, day, 8, 0, 0, 0);
        setData(newData);
        if (day === "" || year === "")
          props.onFieldChange(null, { name: "dataNascita", value: null });
        else props.onFieldChange(null, { name: "dataNascita", value: newData });
        break;
      case "anno":
        if (val.target.value > new Date().getFullYear()) {
          return;
        }
        setYear(val.target.value);
        newData = new Date(val.target.value, month - 1, day, 8, 0, 0, 0);
        setData(newData);
        if (day === "" || month === "") {
          debugger;
          props.onFieldChange(null, { name: "dataNascita", value: null });
        } else {
          props.onFieldChange(null, { name: "dataNascita", value: newData });
        }
        break;
      default:
    }
  };

  if (nazione === "") return null;

  if (nazione === "IT") {
    return (
      <Form.Input
        fluid
        label={"Codice Fiscale"}
        placeholder={"Codice Fiscale"}
        name="cf"
        onChange={props.onFieldChange}
        value={props.cf}
        error={
          props.errors.cf
            ? {
                content: "Codice Fiscale non corretto", //t("cf_error"),
                pointing: "below",
              }
            : null
        }
      ></Form.Input>
    );
  } else {
    return (
      <Form.Group>
        <Form.Input
          fluid
          label={"Luogo e data di nascita"}
          width={10}
          placeholder={"Luogo Nascita"}
          name="luogoNascita"
          onChange={props.onFieldChange}
          value={props.luogoNascita}
          error={
            props.errors.luogoNascita
              ? {
                  content: "Luogo di nascita non corretto", //t("cf_error"),
                  pointing: "below",
                }
              : null
          }
        ></Form.Input>
        <Form.Input
          label="Giorno"
          name="giorno"
          width={2}
          onChange={onDataChange}
          placeholder="GG"
          value={day}
        />
        <Form.Input
          label="Mese"
          name="mese"
          width={2}
          onChange={onDataChange}
          placeholder="MM"
          value={month}
        />
        <Form.Input
          label="Anno"
          name="anno"
          width={3}
          onChange={onDataChange}
          placeholder="AAAA"
          value={year}
          error={
            props.errors.dataNascita
              ? {
                  content: "Data di nascita non corretta", //t("nascita_error"),
                  pointing: "below",
                }
              : null
          }
        />
      </Form.Group>
    );
  }
};

export default FormCliente_CF;
