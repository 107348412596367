import React from "react";
import {
  Card,
  Button,
  Image,
  Transition,
  Segment,
  Icon,
  Message,
} from "semantic-ui-react";
import defaultImgCorso from "../../assets/images/defaults/defaultCourse.jpg";
import imgFiocco from "../../assets/images/buoni/fiocco.png";
import PropTypes from "prop-types";
import useWindowSize from "../../utils/useWindowSize";
import { useTranslation } from "react-i18next";
import { SemanticToastContainer, toast } from "react-semantic-toasts";
import "react-semantic-toasts/styles/react-semantic-alert.css";

function SchedaCategoriaCard({ t, ...props }) {
  function addDefaultSrc(ev) {
    console.log("IMAGE NOT FOUND");
    ev.target.src = defaultImgCorso;
  }

  //const { i18n, t } = useTranslation();

  const windowSize = useWindowSize();
  const unselectedStyle =
    windowSize.width < 600 ? { width: "100%" } : { width: "31%" };
  const selectedStyle =
    windowSize.width < 600
      ? { width: "100%", border: "5px solid rgb(38, 143, 206)" }
      : { width: "31%", border: "7px solid rgb(38, 143, 206)" };

  const picUrl = `https://skiwork.blob.core.windows.net/webimg/${props.codScuola}/TipiCorso/${props.tipologia.pictureWeb1}`;

  return (
    // <Transition visible={props.isVisible} animation="swing up" duration={500}>
    <>
      <SemanticToastContainer position="top-right" />
      <Card
        key={props.tipologia.idTipologiaCorsoWeb}
        className={`schedaCorsoCard`}
        style={props.isSelected ? selectedStyle : unselectedStyle}
      >
        {props.tipologia.pictureWeb1 !== "TEXT" && (
          <div
            style={{
              width: "100%",
              height: "0",
              paddingBottom: "66%",
              position: "relative",
            }}
          >
            <img
              onError={addDefaultSrc}
              src={picUrl}
              alt="category"
              className="skiwork-image"
              width="100%"
              height="100%"
            ></img>
          </div>
        )}
        {props.tipologia.pictureWeb1 === "TEXT" && (
          <>
            {props.tipologia.regalo && (
              <Image
                src={imgFiocco}
                style={{
                  height: "130px",
                  width: "130px",
                  marginBottom: "-40px",
                  float: "left",
                  backgroundColor: "transparent",
                }}
              ></Image>
            )}
            {!props.tipologia.regalo && <div style={{ height: "90px" }}></div>}
            <Card.Content
              textAlign="center"
              className="skiwork-card-header"
              style={{ borderTop: "none" }}
            >
              <span style={{ fontSize: "3.0rem" }}>
                € {props.tipologia.valore.toFixed(2)}
              </span>
            </Card.Content>
          </>
        )}
        {/* <Image onError={addDefaultSrc} src={picUrl}></Image> */}
        <Card.Content style={{ border: "0" }}>
          <Card.Header
            as="h3"
            textAlign="center"
            className="skiwork-card-header"
          >
            {props.tipologia.titolo}
          </Card.Header>
          <Card.Meta
            textAlign="center"
            className="skiwork-card-subheader"
            dangerouslySetInnerHTML={{
              __html: props.tipologia.sottoTitolo,
            }}
          ></Card.Meta>
        </Card.Content>
        <Card.Content
          style={{ marginBottom: "40px", border: "0" }}
          textAlign="center"
        >
          <Segment
            style={{
              position: "absolute",
              bottom: "0px",
              top: "auto",
              left: "0",
              right: "0",
            }}
            basic
            textAlign="center"
          >
            <Button
              primary
              onClick={() => {
                if (props.popup === true) {
                  toast({
                    title: "Carrello",
                    description: "Aggiunto correttamente al carrello",
                    type: "success",
                  });
                }
                props.onSelect(props.tipologia.idTipologiaCorsoWeb);
              }}
              size="big"
            >
              {t("scegli")}
            </Button>
          </Segment>
          {/* <Message positive color="green" style={{}}>
            <Message.Header>Carrello</Message.Header>Buono correttamente
            inserito nel carrello
          </Message> */}
        </Card.Content>
      </Card>
    </>
    // </Transition>
  );
}
SchedaCategoriaCard.propTypes = {
  codScuola: PropTypes.string.isRequired,
  tipologia: PropTypes.object.isRequired,
  isSelected: PropTypes.bool,
  popup: PropTypes.bool,
  onSelect: PropTypes.func.isRequired,
};

export default SchedaCategoriaCard;
