import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import {
  Container,
  Segment,
  Dimmer,
  Loader,
  Input,
  Form,
  Divider,
  Button,
  Icon,
  TextArea,
  Radio,
} from "semantic-ui-react";
import TestataPagina from "../common/TestataPagina";
import FormError from "../common/FormError";
import FormCliente from "../common/FormCliente";
import FormClienteNew from "../common/FormClienteNew";
import * as pagamentiApi from "../../api/pagamentiApi";
import * as emailUtils from "../../utils/emailUtils";
import * as checkoutApi from "../../api/checkoutApi";
import useWindowSize from "../../utils/useWindowSize";
import TestoPrivacy from "../common/TestoPrivacy";
import TestoVendita from "../common/TestoVendita";
import * as gestionePagamenti from "../../utils/GestionePagamenti";
import * as scuolaActions from "../../redux/actions/scuolaActions";
import * as appActions from "../../redux/actions/appActions";
import { setUpSignalRConnectionAxepta } from "../../utils/signalRService";
import { useTranslation } from "react-i18next";
import { validaCodiceFiscale } from "../../utils/cfUtils";
import InfoWeb from "../common/InfoWeb";

function PagamentiMain({
  appData,
  datiScuola,
  loading,
  loadSchoolData,
  codScuola,
  lang,
  setSlug,
  setLang,
  clearAppData,
}) {
  const [busy, setBusy] = useState(false);
  const [trFinalyzing, setTrFinalyzing] = useState(false);
  const [listaNazioni, setListaNazioni] = useState([]);
  const [listaNazioniDial, setListaNazioniDial] = useState([]);
  const [listaLingue, setListaLingue] = useState([]);
  const [listaProvince, setListaProvince] = useState([]);
  const [importo, setImporto] = useState(
    !process.env.NODE_ENV || process.env.NODE_ENV === "development" ? 10 : 0
  );
  const [importoDecimali, setImportoDecimali] = useState(0);
  const [causale, setCausale] = useState(
    !process.env.NODE_ENV || process.env.NODE_ENV === "development"
      ? "TEST"
      : ""
  );
  const [codice, setCodice] = useState("");
  const [codiceValidato, setCodiceValidato] = useState("");
  //const [orderId, setOrderId] = useState("");
  const [importoReadOnly, setImportoReadOnly] = useState(false);
  const [tipoPagamento, setTipoPagamento] = useState(0);
  const [datiCliente, setDatiCliente] = useState(() => {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
      return {
        nome: "TEST NOME",
        cognome: "TEST COGNOME",
        lingua: appData.lang?.toUpperCase() ?? "IT",
        linguaInterfaccia: appData.lang?.toUpperCase() ?? "IT",
        citta: "TEST CITTA",
        provincia: "",
        nazione: (appData.lang?.toLowerCase() ?? "it") === "it" ? "IT" : "",
        cap: "",
        indirizzo: "VIA TEST, 28",
        telefono: "300000000",
        email: "juricapovilla@gmail.com",
        prefisso: (appData.lang?.toLowerCase() ?? "it") === "it" ? "+39" : "",
        cf: "",
        dataNascita: "",
        luogoNascita: "",
      };
    } else {
      return {
        nome: "",
        cognome: "",
        nazione: (appData.lang?.toLowerCase() ?? "it") === "it" ? "IT" : "",
        citta: "",
        provincia: "",
        cap: "",
        indirizzo: "",
        prefisso: (appData.lang?.toLowerCase() ?? "it") === "it" ? "+39" : "",
        lingua: appData.lang?.toUpperCase() ?? "IT",
        linguaInterfaccia: appData.lang?.toUpperCase() ?? "IT",
        telefono: "",
        email: "",
        cf: "",
        dataNascita: "",
        luogoNascita: "",
      };
    }
  });
  const [errors, setErrors] = useState({
    codice: false,
    nome: false,
    cognome: false,
    nazione: false,
    citta: false,
    cap: false,
    provincia: false,
    indirizzo: false,
    email: false,
    telefono: false,
    causale: false,
    importo: false,
    privacy: false,
    condizioniVendita: false,
    cf: false,
    luogoNascita: false,
    dataNascita: false,
  });
  const [condizioniVendita, setCondizioniVendita] = useState(false);
  const [privacy, setPrivacy] = useState(false);
  const [privacy2, setPrivacy2] = useState(false);
  const [privacy3, setPrivacy3] = useState(false);
  const windowSize = useWindowSize();
  const [showCondizioni, setShowCondizioni] = useState(false);
  const [showPrivacy, setShowPrivacy] = useState(false);
  const [showPrivacy2, setShowPrivacy2] = useState(false);
  const [showPrivacy3, setShowPrivacy3] = useState(false);
  const [showAxepta, setShowAxepta] = useState(false);
  const [axeptaPayload, setAxeptaPayload] = useState("");

  //////////////////////////////////////////
  //REFS
  const userDataRef = useRef();

  const history = useHistory();

  const { t, i18n } = useTranslation([datiScuola.customTranslationNamespace]);
  useEffect(() => {
    i18n.emit("languageChanged");
  }, [datiScuola.customTranslationNamespace]);

  const location = useLocation();
  // var payload = "";
  //var orderId = "";
  /////////////////////////////////////////////////
  //EFFECT HOOKS
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search.toLowerCase());
    if (searchParams.has("codice")) {
      setCodice(searchParams.get("codice"));
      cercaCodice(searchParams.get("codice"), true);
    }
  }, [location]);

  useEffect(() => {
    if (
      !datiScuola.hasOwnProperty("id") ||
      appData.slug !== codScuola ||
      appData.lang !== lang
    ) {
      clearAppData();
      loadSchoolData(codScuola, lang);
      setSlug(codScuola.toUpperCase());
      setLang(lang.toUpperCase());
    }
  }, []);

  useEffect(() => {
    if (datiScuola.hasOwnProperty("listaNazioni") && datiScuola !== undefined) {
      setListaNazioni(
        datiScuola.listaNazioni.map((n) => {
          return { key: n.iso, value: n.iso, text: n.nazione };
        })
      );

      setListaProvince(
        datiScuola.listaProvince.map((n) => {
          return { key: n.sigla, value: n.sigla, text: n.nome };
        })
      );

      setListaLingue(
        datiScuola.lingue.map((n) => {
          return {
            key: n.idskiwork,
            value: n.codiceIso,
            text: n.linguaParlata,
          };
        })
      );

      setListaNazioniDial(
        [...datiScuola.listaNazioni]
          .sort((a, b) => {
            const nameA = a.iso.toUpperCase(); // ignore upper and lowercase
            const nameB = b.iso.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }

            // names must be equal
            return 0;
          })
          .filter((n) => {
            return n.dialCode?.length > 2;
          })
          .map((n) => {
            return {
              key: n.iso,
              value: n.dialCode,
              text: n.iso + " " + n.dialCode,
              flag: n.iso.toLowerCase(),
            };
          })
      );
    }

    //aggancio eventuali js esterni
    if (datiScuola.hasOwnProperty("tipoBanca")) {
      if (datiScuola.tipoBanca === 4) {
        const script = document.createElement("script");
        if (datiScuola.banca4 === "1") {
          script.src = "https://pay-test.axepta.it/sdk/axepta-pg-redirect.js";
        } else {
          script.src = "https://pay-test.axepta.it/sdk/axepta-pg-sdk.js";
        }

        script.async = true;
        document.body.appendChild(script);
      }
    }
  }, [datiScuola]);

  useEffect(() => {
    if (showAxepta && axeptaPayload !== "") {
      gestionePagamenti.EffettuaPagamento(axeptaPayload);
      window.scrollTo(0, document.body.scrollHeight);
    }
  }, [showAxepta, axeptaPayload]);

  useEffect(() => {
    if (appData.hasOwnProperty("lang")) {
      i18n.changeLanguage(appData.lang.toLowerCase());
    }
  }, [appData.lang]);

  // useEffect(() => {
  //   console.log("NAZIONE CAMBIATA");

  // }, [datiCliente.nazione]);

  //////////////////////////////////////////////////
  //HANDLERS
  async function handleEffettuaPagamento() {
    if (!checkDati()) {
      userDataRef.current.scrollIntoView(true, {
        behavior: "smooth",
        block: "start",
      });
      return;
    }
    setTrFinalyzing(true);
    var result = await checkoutApi.CheckoutCarrello({
      idScuola: datiScuola.id,
      codScuola: datiScuola.codiceScuola,
      idLingua: datiScuola.idLinguaInUso,
      ipAddress: "",
      cliente: {
        idscuola: datiScuola.id,
        codiceScuola: datiScuola.codiceScuola,
        consensoPrivacy2: privacy2,
        consensoPrivacy3: privacy3,
        ...datiCliente,
      },
      acconti: [
        {
          causale: causale,
          codice: codiceValidato,
          importo: importoReadOnly
            ? parseFloat(
                `${importo.toString()}.${(importoDecimali * 100).toString()}`
              )
            : parseFloat(`${importo.toString()}.${importoDecimali.toString()}`),
        },
      ],
      numBanca: tipoPagamento === 1 ? 2 : 1,
    });

    if (result.result === null) {
      if (result.errorMessage === "DUPLICATE") {
        alert("CODICE non valido o Già utilizzato");
      } else {
        alert("problemi nella richiesta di pamento, riprovare più tardi");
      }
      return;
    }

    if (
      datiScuola.codiceScuola === "DEMOSKIW" ||
      datiScuola.codiceScuola === "DEMOSKI2" ||
      datiScuola.codiceScuola === "PROVADC2"
    ) {
      await checkoutApi.RichiediAckForzato(
        datiScuola.codiceScuola,
        result.payload3
      );
      history.push(
        `/${appData.lang}/${appData.slug}/Checkout/OK?Order_id=${result.payload1}`
      );
      return;
    }

    //debugger;
    if (datiScuola.tipoBanca !== 4) {
      gestionePagamenti.EffettuaPagamento(result);
    } else {
      //orderId(result[3]);
      setAxeptaPayload(result);
      setTrFinalyzing(false);
      setShowAxepta(true);

      //Abilito il client signalR per ricevere il buonfine
      const signalRAxepta = await setUpSignalRConnectionAxepta(
        onAckAxepta,
        onNackAxepta,
        onOkAxepta
      );

      checkoutApi.SetAxeptaConnectionId(
        datiScuola.codiceScuola,
        datiScuola.id,
        result.payload4,
        signalRAxepta.connectionId
      );
    }
  }

  function onAckAxepta(orderId) {
    history.push(
      `/${appData.lang}/${appData.slug}/checkout/ok?order_id=${orderId}`
    );
  }

  function onNackAxepta() {
    history.push(`/${appData.lang}/${appData.slug}/checkout/ko`);
  }

  function onOkAxepta() {
    setTrFinalyzing(true);
  }

  function handleCodiceChange(ev, data) {
    setErrors({ ...errors, codice: false });
    if (data.value.length <= 8) setCodice(data.value);
  }

  function handleFieldChange(ev, data) {
    if (data.name === "cap") {
      if (data.value.length > 6) data.value = data.value.substring(0, 6);
    }

    if (data.name === "telefono") {
      const re = /^[0-9\b]+$/;
      if (data.value !== "" && !re.test(data.value)) {
        data.value = datiCliente.telefono;
      }
    }

    if (data.name === "nazione") {
      const nation = listaNazioniDial.find((x) => x.key === data.value);
      datiCliente.prefisso = nation.value;
    }

    let datiTmp = { ...datiCliente };
    datiTmp[data.name] = data.value;
    setErrors({ ...errors, [data.name]: false });
    setDatiCliente(datiTmp);
  }

  function handleImportoChange(ev, data) {
    if (isNaN(data.value)) return;
    if (data.value.length === 0) {
      setImporto(0);
      return;
    }
    if (data.value.length > 5) return;
    setImporto(parseFloat(data.value));

    setErrors({ ...errors, importo: false });
  }

  function handleImportoDecimaliChange(ev, data) {
    if (isNaN(data.value)) return;
    if (data.value.length === 0) {
      setImportoDecimali(0);
      return;
    }
    if (data.value.length > 2) return;
    setImportoDecimali(parseFloat(data.value));

    setErrors({ ...errors, importo: false });
  }

  function handleCausaleChange(ev, data) {
    if (data.value.length < 255) {
      setCausale(data.value);
      setErrors({ ...errors, causale: false });
    }
  }

  function handlePrivacyChange(ev, data) {
    setPrivacy(data.checked);
    setErrors({ ...errors, privacy: !data.checked });
  }
  function handlePrivacy2Change(ev, data) {
    setPrivacy2(data.checked);
  }
  function handlePrivacy3Change(ev, data) {
    setPrivacy3(data.checked);
  }
  function handleCondizioniVenditaChange(ev, data) {
    setCondizioniVendita(data.checked);
    setErrors({ ...errors, condizioniVendita: !data.checked });
  }

  const handleTipoPagamentoChange = (e, { value }) => {
    setTipoPagamento(value);
  };

  ////////////////////////////////////////////////////
  //HELPERS
  async function cercaCodice(cod, fromURL) {
    var codTmp = codice;
    if (cod !== undefined) {
      codTmp = cod;
      setCodice(cod);
    }

    if (codTmp.length !== 6) {
      setCodice("");
      setCodiceValidato("");
      setErrors({ ...errors, codice: true });
      setTimeout(() => {
        setErrors({ ...errors, codice: false });
      }, 4000);
      if (fromURL === true) {
        history.push(`/${appData.lang}/${appData.slug}/pagamenti/codeerror`);
      }
      return;
    }

    const codScuolaOk =
      appData.slug !== codScuola ? codScuola : datiScuola.codiceScuola;
    setBusy(true);
    var res = await pagamentiApi.getDatiAcconto(codScuolaOk, codTmp);

    setBusy(false);
    if (res === null) {
      setCodice("");
      setCodiceValidato("");
      setErrors({ ...errors, codice: true });
      if (fromURL === true) {
        history.push(`/${appData.lang}/${appData.slug}/pagamenti/codeerror`);
      }
      setTimeout(() => {
        setErrors({ ...errors, codice: false });
      }, 4000);
    } else {
      const regex = /^[a-zA-Z]+$/;
      res.nazione = regex.test(res.nazione) ? res.nazione : "";
      setDatiCliente({
        ...datiCliente,
        nome: res.nome,
        cognome: res.cognome,
        nazione: res.nazione,
        citta: res.citta,
        cap: res.cap,
        indirizzo: res.indirizzo,
        prefisso: res.prefisso,
        telefono: res.telefono,
        email: res.email,
      });
      setImporto(Math.floor(res.importo));
      setImportoDecimali(res.importo % 1);
      setImportoReadOnly(true);
      setCodiceValidato(cod);
      setErrors({ ...errors, codice: false });
    }
  }

  function checkDati() {
    let checkOk = true;
    var newErrors = { ...errors };
    if (datiCliente.nome.trim().length < 2 || datiCliente.nome > 50) {
      newErrors.nome = true;
      checkOk = false;
    }
    if (datiCliente.cognome.trim().length < 2 || datiCliente.cognome > 50) {
      newErrors.cognome = true;
      checkOk = false;
    }

    const regex = /^[a-zA-Z]+$/;
    if (datiCliente.nazione.length !== 2 || !regex.test(datiCliente.nazione)) {
      newErrors.nazione = true;
      datiCliente.nazione = "";
      checkOk = false;
    }

    if (datiCliente.citta.trim().length < 2 || datiCliente.citta.length > 50) {
      newErrors.citta = true;
      checkOk = false;
    }

    if (
      datiCliente.indirizzo.trim().length < 2 ||
      datiCliente.indirizzo.length > 50
    ) {
      newErrors.indirizzo = true;
      checkOk = false;
    }

    if (datiCliente.cap === null) datiCliente.cap = "";
    //CAP
    if (datiCliente.nazione === "IT") {
      const regexCAP_IT = /^\d{5}$/;
      if (
        datiCliente.cap.length !== 5 ||
        datiCliente.cap === "00000" ||
        !regexCAP_IT.test(datiCliente.cap)
      ) {
        newErrors.cap = true;
        checkOk = false;
      } else {
        if (datiCliente.cap.length < 3 || datiCliente.cap.length > 15) {
          datiCliente.cap = "00000";
        }
      }
    }
    //PROVINCIA
    if (datiCliente.nazione === "IT") {
      if (datiCliente.provincia === null) datiCliente.provincia = "";
      if (datiCliente.provincia.length !== 2) {
        newErrors.provincia = true;
        datiCliente.provincia = "";
        checkOk = false;
      }
    }

    if (
      datiCliente.email.length < 2 ||
      datiCliente.email.length > 50 ||
      !emailUtils.ValidateEmail(datiCliente.email)
    ) {
      newErrors.email = true;
      checkOk = false;
    }

    if (datiCliente.telefono.length < 5 || datiCliente.telefono.length > 15) {
      newErrors.telefono = true;
      checkOk = false;
    }

    if (privacy === false) {
      newErrors.privacy = true;
      checkOk = false;
    }

    if (condizioniVendita === false) {
      newErrors.condizioniVendita = true;
      checkOk = false;
    }

    if (!importoReadOnly) {
      if (importo <= 0) {
        newErrors.importo = true;
        checkOk = false;
      }
      if (causale.length < 2) {
        newErrors.causale = true;
        checkOk = false;
      }
    } else {
      newErrors.importo = false;
      newErrors.causale = false;
    }

    //Verifico se ho attivato il Codice Fiscale obbligatorio
    if (datiScuola.usaCodiceFiscale || datiScuola.fatturazione) {
      if (datiCliente.nazione.length > 0) {
        if (datiCliente.nazione === "IT") {
          newErrors.cf = false;
          if (!validaCodiceFiscale(datiCliente.cf)) {
            newErrors.cf = true;
            checkOk = false;
          }
        } else {
          if (datiScuola.usaCodiceFiscale) {
            newErrors.luogoNascita = false;
            newErrors.dataNascita = false;
            if (datiCliente.luogoNascita.length < 3) {
              newErrors.luogoNascita = true;
              checkOk = false;
            }
            if (
              datiCliente.dataNascita === "" ||
              datiCliente.dataNascita === null ||
              datiCliente.dataNascita === undefined
            ) {
              newErrors.dataNascita = true;
              checkOk = false;
            }
          } else {
            newErrors.luogoNascita = false;
            newErrors.dataNascita = false;
          }
        }
      }
    }

    setErrors(newErrors);

    return checkOk;
  }

  if (
    loading ||
    trFinalyzing ||
    datiScuola === undefined ||
    Object.keys(datiScuola).length === 0
  ) {
    return <Loader size="huge" active />;
  } else {
    return (
      <Container>
        <div className="head-offset"></div>
        <TestataPagina
          titolo={t("pagamento_lezione")}
          icon="credit card"
          sottoTitolo={t("effettua_qui_pagamento")}
        />
        <Segment
          className="padding_5_lr contenitore-grande"
          style={{ marginBottom: "30px" }}
        >
          <Dimmer active={busy} inverted>
            <Loader active size="huge">
              VERIFICA CODICE
            </Loader>
          </Dimmer>
          <Segment
            textAlign="center"
            basic
            style={{ paddingLeft: "0px", paddingRight: "0px" }}
          >
            <div ref={userDataRef}></div>
            <Segment inverted className="skiwork-header">
              Booking Code
            </Segment>
            <label>{t("codice")}</label>
            <Input
              value={codice}
              onChange={handleCodiceChange}
              action={{
                icon: "search",
                onClick: () => cercaCodice(codice),
              }}
              style={{ width: "100px" }}
            ></Input>
            {errors.codice && <FormError errore="CODICE NON VALIDO" />}
          </Segment>

          <Segment inverted className="skiwork-header" textAlign="center">
            {t("dati_cliente")}
          </Segment>
          <FormClienteNew
            datiCliente={datiCliente}
            datiScuola={datiScuola}
            handleFieldChange={handleFieldChange}
            listaNazioni={listaNazioni}
            listaNazioniDial={listaNazioniDial}
            listaProvince={listaProvince}
            listaLingue={listaLingue}
            errors={errors}
            t={t}
            fatturazione={datiScuola.fatturazione}
          />
          {/* <Divider />
          <FormCliente
            datiCliente={datiCliente}
            datiScuola={datiScuola}
            handleFieldChange={handleFieldChange}
            listaNazioni={listaNazioni}
            listaNazioniDial={listaNazioniDial}
            listaLingue={listaLingue}
            errors={errors}
            t={t}
          /> */}

          <Segment inverted className="skiwork-header" textAlign="center">
            {t("importo")}
          </Segment>
          <table style={{ marginLeft: "auto", marginRight: "auto" }}>
            <tbody>
              <tr>
                {importoReadOnly && (
                  <>
                    <td>
                      <p
                        style={{ textAlign: "right" }}
                        className="prezzo_totale"
                      >
                        {datiScuola.valuta}{" "}
                        {`${importo},${(importoDecimali * 100)
                          .toFixed(0)
                          .toString()
                          .padStart(2, "0")}`}
                        {/* {`${importo},${importoDecimali
                          .toString()
                          .padStart(2, "0")}`} */}
                      </p>
                    </td>
                  </>
                )}
                {!importoReadOnly && (
                  <>
                    <td>
                      <Input
                        style={{ width: "100px" }}
                        iconPosition="left"
                        value={importo}
                        onChange={handleImportoChange}
                        error={errors.importo ? {} : null}
                      >
                        <Icon name="euro sign"></Icon>
                        <input style={{ textAlign: "right" }} />
                      </Input>
                    </td>
                    <td> , </td>
                    <td style={{ verticalAlign: "bottom" }}>
                      <Input
                        style={{ width: "50px" }}
                        value={importoDecimali}
                        onChange={handleImportoDecimaliChange}
                        error={errors.importo ? {} : null}
                      ></Input>
                    </td>
                  </>
                )}
              </tr>
              <tr>
                <td colSpan="3">
                  {errors.importo && <FormError errore={t("importo_error")} />}
                </td>
              </tr>
            </tbody>
          </table>

          <Segment inverted className="skiwork-header" textAlign="center">
            {t("causale")}
          </Segment>
          <div style={{ textAlign: "center" }}>
            <Form>
              <TextArea
                style={{ width: windowSize.width > 600 ? "400px" : "100%" }}
                placeholder={t("causale_note")}
                value={causale}
                onChange={handleCausaleChange}
              ></TextArea>
            </Form>
            {errors.causale && <FormError errore={t("causale_error")} />}
          </div>

          {datiScuola.infoWeb !== undefined && (
            <div
              style={{
                //marginLeft: windowSize.width > 600 ? "1em" : "2px",
                marginTop: "2em",
              }}
            >
              <InfoWeb
                testi={datiScuola.infoWeb.filter((x) => x.sezione === 2)}
                sezione={2}
              />
            </div>
          )}

          <Segment inverted className="skiwork-header" textAlign="center">
            {t("condizioni_vendita_privacy")}
          </Segment>
          <Form>
            <div
              style={{ cursor: "pointer", marginBottom: "14px" }}
              onClick={() => {
                setShowCondizioni(true);
              }}
            >
              <a href>
                {t("condizioni_vendita")} <Icon name="external"></Icon>
              </a>
            </div>
            <Form.Checkbox
              //style={{ float: "left" }}
              label={t("accetto_condizioni")}
              checked={condizioniVendita}
              onChange={handleCondizioniVenditaChange}
              error={
                errors.condizioniVendita
                  ? {
                      content: t("necessario_accettare_vendita"),
                      pointing: "left",
                    }
                  : null
              }
            ></Form.Checkbox>
            <Divider />
            <div
              style={{ cursor: "pointer", marginBottom: "14px" }}
              onClick={() => {
                setShowPrivacy(true);
              }}
            >
              <a href>
                {t("informativa")} <Icon name="external"></Icon>
              </a>
            </div>
            <Form.Checkbox
              label={t("acconsento_privacy")}
              checked={privacy}
              onChange={handlePrivacyChange}
              error={
                errors.privacy
                  ? {
                      content: t("necessario_accettare_privacy"),
                      pointing: "left",
                    }
                  : null
              }
            ></Form.Checkbox>
            {datiScuola.richiediPrivacy2 && (
              <>
                <Form.Checkbox
                  label={datiScuola.titoloPrivacy2}
                  checked={privacy2}
                  name="privacy2"
                  onChange={handlePrivacy2Change}
                ></Form.Checkbox>
                {(datiScuola.testoPrivacy2?.length ?? 0) > 0 && (
                  <p
                    style={{
                      marginLeft: "32px",
                      marginTop: "-14px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setShowPrivacy2(true);
                    }}
                  >
                    <a href>
                      Maggiori informazioni <Icon name="external"></Icon>
                    </a>
                  </p>
                )}
              </>
            )}
            {datiScuola.richiediPrivacy3 && (
              <>
                <Form.Checkbox
                  label={datiScuola.titoloPrivacy3}
                  checked={privacy3}
                  name="privacy3"
                  onChange={handlePrivacy3Change}
                ></Form.Checkbox>
                {(datiScuola.testoPrivacy3?.length ?? 0) > 0 && (
                  <p
                    style={{
                      marginLeft: "32px",
                      marginTop: "-14px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setShowPrivacy3(true);
                    }}
                  >
                    <a href>
                      Maggiori informazioni <Icon name="external"></Icon>
                    </a>
                  </p>
                )}
              </>
            )}
          </Form>
          <Divider />
          {datiScuola.tipoBanca_2 > 0 && (
            <>
              <Segment
                inverted
                className="skiwork-header"
                style={{ textAlign: "center" }}
              >
                PAGAMENTO
              </Segment>
              <div className="container-tipopagamento">
                <Form className="form-tipopagamento">
                  <Form.Field className="field-tipopagamento">
                    <Radio
                      label="Carta di credito"
                      value={0}
                      checked={tipoPagamento === 0}
                      onChange={handleTipoPagamentoChange}
                    />
                  </Form.Field>
                  <div className="scalapay-container">
                    <Form.Field className="field-tipopagamento scalapay">
                      <Radio
                        label={`Scalapay - Paga in 3 rate da € ${(
                          (importo + importoDecimali / 100) /
                          3
                        ).toFixed(2)}`}
                        value={1}
                        checked={tipoPagamento === 1}
                        onChange={handleTipoPagamentoChange}
                      />
                      <img
                        src={require(`../../assets/images/scalapay/badge.png`)}
                        alt="scalapay logo"
                        className="scalapay-logo"
                      />
                    </Form.Field>
                  </div>

                  <Button
                    size="huge"
                    primary
                    onClick={handleEffettuaPagamento}
                    style={{ width: "100%" }}
                  >
                    {t("effettua_pagamento")}
                  </Button>
                </Form>
              </div>
            </>
          )}
          {datiScuola.tipoBanca_2 <= 0 && (
            <>
              {!showAxepta && (
                <p style={{ textAlign: "center" }}>
                  <Button size="huge" primary onClick={handleEffettuaPagamento}>
                    {t("effettua_pagamento")}
                  </Button>
                </p>
              )}
              {showAxepta && (
                <Segment textAlign="center" basic>
                  <div id="my-axepta-sdk-pg" style={{ margin: "0 auto" }}></div>
                </Segment>
              )}
            </>
          )}
        </Segment>
        <TestoPrivacy
          isOpen={showPrivacy}
          onClose={() => {
            setShowPrivacy(false);
          }}
          t={t}
        />
        <TestoPrivacy
          isOpen={showPrivacy2}
          onClose={() => {
            setShowPrivacy2(false);
          }}
          t={t}
          numRichiesta={2}
        />
        <TestoPrivacy
          isOpen={showPrivacy3}
          onClose={() => {
            setShowPrivacy3(false);
          }}
          t={t}
          numRichiesta={3}
        />
        <TestoVendita
          isOpen={showCondizioni}
          onClose={() => {
            setShowCondizioni(false);
          }}
          t={t}
        />
      </Container>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    codScuola: ownProps.match.params.slug,
    lang: ownProps.match.params.lang,
    appData: state.appReducer,
    datiScuola: state.scuolaReducer,
    loading: state.apiCallsInProgress > 0,
  };
}

const mapDispatchToProps = {
  loadSchoolData: scuolaActions.loadSchoolData,
  setSlug: appActions.setSlug,
  setLang: appActions.setLang,
  clearAppData: appActions.clearAppData,
};

export default connect(mapStateToProps, mapDispatchToProps)(PagamentiMain);
